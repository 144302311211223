import HeaderDefaultWithLogo from "@components/web/HeaderDefaultWithLogo";
import { FrontPageLayout } from "@components/web/layouts/FrontPageLayout";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";

export default function JobPage() {
  return (
    <>
      <FrontPageLayout removeBlueBodyBackground withoutAppBar>
        <Container
          style={{
            margin: "auto",
            width: "100%",
            maxWidth: "800px",
            padding: "25px",
            paddingBottom: "40px",
            background: "#fff",
          }}
        >
          <HeaderDefaultWithLogo />

          <Typography
            style={{
              marginTop: "40px",
              fontWeight: "bold",
            }}
          >
            Reshopper søger knivskarpe kundesupportere til at sikre den bedste
            brugeroplevelse
          </Typography>
          <Typography>
            Hver dag får tusindvis af brugte børneting et nyt liv hos en ny
            familie, når der handles på Reshopper - og langt de fleste handler
            går helt efter planen. Men i enkelte tilfælde opstår der spørgsmål
            eller udfordringer, og her kommer du ind i billedet.
          </Typography>

          <Typography style={{ fontWeight: "bold", marginTop: "25px" }}>
            <TrendingFlatIcon
              style={{ verticalAlign: "text-bottom", fontSize: "1.3em" }}
            />{" "}
            Er du serviceminded, god til at løse problemer og på udkig efter et
            studiejob? Så er det måske dig, vi søger som en af vores nye
            kundesupportere.
          </Typography>

          <Typography style={{ fontWeight: "bold", marginTop: "25px" }}>
            Arbejdsopgaver
          </Typography>
          <Typography>
            Som kundesupporter hos Reshopper bliver din primære opgave at hjælpe
            vores brugere med spørgsmål og udfordringer, de måtte støde på i
            forbindelse med brugen af vores platform. Dette kan være alt fra
            tekniske problemer til spørgsmål om køb og salg, eller efterlysning
            af en pakke hos fragtleverandøren. Det er vigtigt, at vores brugere
            føler sig hørt og forstået, og at du kan guide dem til den bedste
            løsning gennem skriftlig dialog.
          </Typography>

          <Typography style={{ fontWeight: "bold", marginTop: "25px" }}>
            Kort om stillingen
          </Typography>

          <ul>
            <li>
              <Typography>
                Stillingen er på 10-15 timer ugentligt, primært fordelt på
                aften- og weekendvagter, så du har mulighed for at prioritere
                dit studie.
              </Typography>
            </li>
            <li>
              <Typography>
                Arbejdet kan udføres remote, så du kan arbejde hjemmefra eller
                hvor det passer dig bedst.
              </Typography>
            </li>
            <li>
              <Typography>
                Vi søger kandidater i eller omkring Aarhus eller København, så
                du har mulighed for at deltage i teamaktiviteter og møder efter
                behov.
              </Typography>
            </li>
          </ul>

          <Typography style={{ fontWeight: "bold", marginTop: "25px" }}>
            Dine kvalifikationer
          </Typography>
          <ul>
            <li>
              <Typography>
                Du er i begyndelsen eller midtvejs i din bachelor- eller
                kandidatuddannelse.
              </Typography>
            </li>
            <li>
              <Typography>
                Det er en fordel, hvis du har erfaring med kundesupport eller
                lignende arbejde.
              </Typography>
            </li>
            <li>
              <Typography>
                Du har særdeles gode formuleringsevner, skriver flydende dansk
                og kan forstå og skrive engelsk.
              </Typography>
            </li>
            <li>
              <Typography>
                Du er serviceminded og har altid fokus på at give den bedst
                mulige brugeroplevelse.
              </Typography>
            </li>
            <li>
              <Typography>
                Du kan arbejde selvstændigt og er god til at tage initiativ.
              </Typography>
            </li>
            <li>
              <Typography>
                Du er hurtig på tasterne uden at gå på kompromis med kvaliteten
                af dit arbejde.
              </Typography>
            </li>
          </ul>

          <Typography style={{ fontWeight: "bold", marginTop: "25px" }}>
            Kort om os
          </Typography>
          <Typography>
            Reshopper er Danmarks førende platform for køb og salg af brugte
            børneting. Vi brænder for at gøre det nemt og sikkert for familier
            at give børnenes ting nyt liv. Vi har en uformel og dynamisk
            arbejdskultur med højt til loftet, hvor vi værdsætter samarbejde og
            gode idéer.
          </Typography>

          <Typography style={{ fontWeight: "bold", marginTop: "25px" }}>
            Vi tilbyder desuden
          </Typography>
          <ul>
            <li>
              <Typography>
                Fleksibilitet i arbejdstiderne, så det passer med dit studie og
                eventuelle eksamensperioder.
              </Typography>
            </li>
            <li>
              <Typography>
                Et godt og socialt arbejdsmiljø med masser af humor og
                sammenhold.
              </Typography>
            </li>
            <li>
              <Typography>
                Mulighed for at arbejde remote, så du kan arbejde hvor det
                passer dig bedst.
              </Typography>
            </li>
            <li>
              <Typography>
                Løbende sparring og udvikling, så du kan blive endnu bedre til
                dit arbejde.
              </Typography>
            </li>
            <li>
              <Typography>
                Mulighed for at blive en del af et engageret team, der brænder
                for bæredygtighed og genbrug.
              </Typography>
            </li>
          </ul>

          <Typography>
            Kan du genkende dig selv i ovenstående, og lyder det som noget for
            dig? Så send os en ansøgning hurtigst muligt.
          </Typography>

          <Typography style={{ marginTop: "25px" }}>
            Ansøgning og CV bedes sendt til{" "}
            <a href="mailto:lasse@reshopper.com" style={{color: "#62aed6"}}>lasse@reshopper.com</a>
          </Typography>
          <Typography></Typography>
          <Typography style={{ fontWeight: "bold", marginTop: "25px" }}>
            Vi glæder os til at høre fra dig!
          </Typography>
        </Container>
      </FrontPageLayout>
    </>
  );
}
